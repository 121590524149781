import React, { useEffect, useState } from "react";

import './Home.css';
import { Tabs } from "../../Components/Tabs/Tabs";
import { TabsItem } from "../../Const/TabsConst";
import { FireIcon } from "../../Icons/solid/FireIcon";
import { Carousel } from "../../Components/Carousel/Carousel";
import { CarouselHoroscopeMin } from "../../Components/CarouselHoroscopeMin/CarouselHoroscopeMin";
import { Giveaways } from "../../Components/Giveaways/Giveaways";
import { CardUser } from "../../Components/CardUser/CardUser";
import { CardSection } from "../../Components/CardSection/CardSection";
import { ZodiacSignInterface } from "../../Interfaces/ZodiacSignInterface";
import { HoroscopeInterface } from "../../Interfaces/HoroscopeInterface";
import { GetAllZodiacalSign } from "../../Service/ZodiacSign.service";
import { GetHoroscopeReadingRealTime, GetHoroscopeReaingByZodiacSign } from "../../Service/HoroscopeReading.service";
import { FormatDateMonth } from "../../Utils/FormartDateMonth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../Store/store";
import { GetPlanDetail } from "../../Slice/Plan";
import { StoreComponent } from "../../Components/Store/StoreComponent";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";
import { CardGradient } from "../../Components/CardGradient/CardGradient";

interface HomeProps {
}

export const Home: React.FC<HomeProps> = () => {

  const dispatch = useDispatch<AppDispatch>();

  const {userDetail} = useSelector((state: any) => state.user);
  const {planDetail} = useSelector((state: any) => state.plan);

  const parentRef = React.useRef<HTMLDivElement>(null);

  // useEffect to controll loading
  const [loading, setLoading] = useState<boolean>(false);

  const [activeSubMenu, setActiveSubmenu] = useState('HoróZcopo'); // muestra section activo 
  const [activeHoroscope, setActiveHoroscope] = React.useState(0);

  const [zodiacSigns, setZodiacSigns] = useState<ZodiacSignInterface[]>([]) // ZodiacSignInterface[]
  const [horoscopeReading, setHoroscopeReading] = useState<HoroscopeInterface[]>([]);


  const handleItemZodiacSign = (index: number) => {
    setActiveHoroscope(index);
    getHoroscopeReadingByZodiacSign(zodiacSigns[index].id || "");
  }

  // method to get all zodiacal signs
  const getZodiacSigns = async () => {
    setLoading(false);
    if(!loading) {
     try {
        setLoading(true);
        const response = await GetAllZodiacalSign();
        if(response.length > 0) {
            setZodiacSigns(response);
            setActiveHoroscope(response.findIndex(c => c.id === userDetail.zodiacSignId) || 0);
            await getHoroscopeReadingByZodiacSign(response[0].id || "");  
        }
        setLoading(false);
        } catch (error) {
            setLoading(false);    
            console.log(error);
        }
    } 
  }

  // method to get horoscope reading by zodiac sign
  const getHoroscopeReadingByZodiacSign = async (zodiacSignId: string) => {
      if(!loading && zodiacSignId.length > 0) {
          try {
              // setLoading(true);
              // const response = await GetHoroscopeReaingByZodiacSign(zodiacSignId);
              // setHoroscopeReading(response);
              // setLoading(false);    
              GetHoroscopeReadingRealTime(zodiacSignId, (response) => {
                setHoroscopeReading(response);
              });
          } catch (error) {
              setLoading(false);    
              console.log(error);
          }
      }
  }

  const getPlanDetail = (planId: string) => {
    if(loading) return;
    setLoading(true)
    dispatch(GetPlanDetail(planId))
    .unwrap()
    .then((response) => {
        setLoading(false);
    })
    .catch((error) => {
        console.log("🚀 ~ file: Orders.tsx ~ line 264 ~ getUser ~ error", error)
        setLoading(false);
    });
  }

  useEffect(() => {
      getZodiacSigns();
      parentRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    getPlanDetail(userDetail.planId);
  }, [userDetail.planId]);

  return (
    <div className="home" style={{backgroundImage:`url(${STORAGE_ASSETS}/images/png/ZINOS_IMAGEN_FONDO_DESKTOP_MENOR-TA.png`}}>
      <div className="home-content">
        <div className="home-div home-left">
          {userDetail && userDetail.id &&  
          <CardUser
            title="Bienvenid@ "
            photoUrl={userDetail.photoUrl}
            nameUser={userDetail.name+" "+userDetail.fathersLastName}
            planName={"Suscriptor "+planDetail.title}
            sign={userDetail.zodiacSignName}
          />}
          <StoreComponent/>
          <CardSection/>
        </div>

        {(activeSubMenu === 'HoróZcopo' || window.innerWidth >= 690) &&
        <div className="home-div home-center">
          <div className="home-content-tabs">
            { TabsItem.length>0 && TabsItem.map((item, idx) => {
              return(
                <Tabs 
                  key={idx}
                  content={item.name} 
                  active={activeSubMenu === item.name} 
                  handleClick={()=>{ setActiveSubmenu(item.name);}} 
                />
              )
            })}
          </div>
          <div className="home-center-body">
            {/** Lista de signos */}
            <CarouselHoroscopeMin 
              listHoroscope={zodiacSigns.map((c, idx) => ({
                iconWhiteUrl: c.iconWhiteUrl || "" , 
                iconBlackUrl: c.iconBlackUrl || "" ,
                name: c.name,
              }))} 
              activeHoroscope={activeHoroscope}
              setActiveHoroscope={(i:any) => handleItemZodiacSign(i)} 
            />

            { (activeHoroscope !== -1 && zodiacSigns.length>0 && horoscopeReading.length>0) ?
              <Carousel
                AstrologicalSignImg={zodiacSigns[activeHoroscope].iconWhiteUrl || ""}
                AstrologicalSign={zodiacSigns[activeHoroscope].name}
                position="column"
                lastHoroscopes={horoscopeReading.map((c, idx) => ({
                  id: c.id || "",
                  icon: idx === 0 ? <FireIcon /> : "",
                  title: idx === 0 ? 'Lo más nuevo' : "Horóscopo anterior",
                  typeReading: c.typeReading,
                  description: c.description,
                  numLikes: c.like.length,
                  isLiked: c.like.findIndex(c => c.userId === userDetail.id) !== -1,
                  createdString: FormatDateMonth(c.createdAt.toDate())+" / "+c.hourCreated
                }))}
              /> : 
              <CardGradient className="carousel-lecture"> 
              </CardGradient>
            }
          </div>
        </div>}

        {(activeSubMenu === 'Sorteos' || window.innerWidth >= 690) &&
        <div className="home-div home-right">
          <div className="home-content-tabs">
            { TabsItem.length>0 && TabsItem.map((item, idx) => {
              return(
                <Tabs 
                  key={idx}
                  content={item.name} 
                  active={activeSubMenu === item.name} 
                  handleClick={()=>{ setActiveSubmenu(item.name);}} 
                />
              )
            })}
          </div>
          <Giveaways/>
          <div className="tinytext home-right-info">
           *Si ganas un premio lo publicaremos y nos vamos a comunicar contigo.
          </div>
        </div>}
      </div>
    </div>
  )
}