import React, { useEffect, useState } from "react";

// Importando estilo
import "./PlanSection.css";
import { HeaderSection } from "../HeaderSection/HeaderSection";
import { Plan } from "../Plan/Plan";
import { GetActivePlans } from "../../Service/Plan.service";
import { PlanInterface } from "../../Interfaces/PlansInterface";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AUTH_MODAL, AUTH_REGISTER } from "../../Const/BaseUrlConst";

interface PlanSectionProps {
}

export const PlanSection: React.FC<PlanSectionProps> = ({

}) => {
    const navigation = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [plans, setPlans] = useState<PlanInterface[]>([]);

    const getAllActivePlan = async () => {
        if(!loading) {
            try {
                setLoading(true);
                const response = await GetActivePlans();
                setPlans(response);
                setLoading(false);
            } catch (error) {
                console.log("🚀 ~ getAllActivePlan ~ error:", error);
                setLoading(false);
            }
        }
    
    }

    const handlePlanClick = (plan: PlanInterface) => {
        if(plan) {
            navigation(`/?${AUTH_MODAL}=${AUTH_REGISTER}&planId=${plan?.id}&registerStep=1`);  
        } else {
            toast.warning("Selecciona un plan para continuar");
        }

    }

    useEffect(() => {
        getAllActivePlan();
    }, []);

    return (
        <section id="planSection" className="planSection">
            {/* <div className="planSection-animation">
                <Animation
                    animation={ '/animations/Mobile/Zinos_Animación_Constelación_02/Zinos_Animacion_Constelacion_02.json'}
                    nameAnimation="Zinos_Animacion_Constelacion_02"t
                /> 
            </div> */}
            <div className="planSection-body">
                <HeaderSection 
                    title="Niveles"
                    subTitle="Elige el plan que más te guste, TODOS incluyen SORTEOS SEMANALES y CONTENIDO EXCLUSIVO."
                />
                <div className="planSection-scroll">
                    { <div className="planSection-row">
                        {plans.length>0 && plans.map((item, idx) => {
                            return (
                                <Plan
                                    key={idx}
                                    plan={item}
                                    onclickPlan={handlePlanClick}
                                    index={idx}
                                />
                            )
                        })}
                    </div>}
                    {/* <PlansLoader /> */}
                </div>
            </div>

            {/* <div className="body-effect"></div> */}
        </section>
    );
};
